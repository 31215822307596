import { Pipe, PipeTransform } from '@angular/core';
import { NgxLinkifyjsService } from 'ngx-linkifyjs-v2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'applinkifyHtml',
})
export class NgxLinkifyjsHtmlPipe implements PipeTransform {
  constructor(
    public linkifyService: NgxLinkifyjsService,
    private sanitizer: DomSanitizer
  ) {}

  // transform(value: string, options?: any): string {
  //   console.log('value----------->' , value)
  //   const links = this.linkifyService.find(value);
  //   console.log('links----------->' , links)
  //   console.log("//////////" , links
  //     .map((link) => {
  //       return `<a href="${link.href}" target="_blank">${link.value}</a>`;
  //     })
  //     .join(''))
  //   return links
  //     .map((link) => {
  //       return `<a href="${link.href}" target="_blank">${link.value}</a>`;
  //     })
  //     .join('');
  // }
  transform(value: string, options?: any): SafeHtml {
    if (!value) return value;
    let transformedHtml = this.replaceMentions(value);
    const links = this.linkifyService.find(transformedHtml);
    links.forEach((link) => {
      transformedHtml = transformedHtml.replace(
        link.value,
        `<a href="${link.href}" target="_blank">${link.value}</a>`
      );
    });
    return this.sanitizer.bypassSecurityTrustHtml(transformedHtml);
  }

  private replaceMentions(html: string): string {
    return html.replace(
      /<span class="mention" data-mention="(@\w+)">(.+?)<\/span>/g,
      (match, mention) => {
        const username = mention.substring(1);
        return `<span class="mention"><a href="/profile/view-profile/${username}" class="linkify linkify-mention">${mention}</a></span>`;
      }
    );
  }
}
