import { BlockProfileDTO } from './blockProfile.modal';
import { Category } from './category.model';
import { Field } from './field.model';

//Used as Data Transfer object for transferring details of Profile
export class Profile {
  id: number;
  profileId: number;
  profileName: string;
  claimedFlag: string;
  profileImage: string;
  claimedUserId: number;
  categories: Category[] | null;
  createdDate: Date;
  details: Field[];
  createdBy: number;
  updatedBy?: number | null;
  updatedDate?: Date | null;
  tagName: string;
  defaultProfile: boolean;
  officename: string;
  officailname: string;
  iconFilepath: string;
  divisionname: string;
  level: string;
  isDelete: boolean;
  blockedBySearchProfile?: boolean;
  blockedSearchProfile?: boolean;
  blockProfileDTO?: BlockProfileDTO;
  verified?: string;
  lastIndex?: boolean;

  constructor() {
    this.id = 0;
    this.profileId = 0;
    this.profileName = '';
    this.claimedFlag = '';
    this.profileImage = '';
    this.claimedUserId = 0;
    this.categories = null;
    this.createdDate = new Date();
    this.details = [];
    this.updatedBy = 0;
    this.updatedDate = new Date();
    this.createdBy = 0;
    this.tagName = '';
    this.defaultProfile = true;
    this.officename = '';
    this.officailname = '';
    this.iconFilepath = '';
    this.divisionname = '';
    this.level = '';
    this.isDelete = false;
    this.blockedBySearchProfile = false;
    this.blockedSearchProfile = false;
    this.blockProfileDTO = new BlockProfileDTO();
    this.verified = '';
    this.lastIndex = false;
  }
}
